@import '../variables';

.btn {
  position: relative;
  height: 50px;
  padding: 14px 40px;
  min-width: 159px;
  max-width: 100%;
  font-size: 15px;
  color: $white;
  border-radius: 5px;
  line-height: 1;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    opacity: 0.3;
  }

  &--primary {
    border: 2px solid $brand-color;
    background-color: $brand-color;
    color: $white;
    paddiubng

    &:hover {
      background-color: $brand-color-hover;
    }

    &.btn--outline {
      color: $brand-color;
      background-color: transparent;

      &:hover {
        background-color: $brand-color;
        color: $white;
      }

      &.active {
        background-color: $brand-color;
        color: $white;
      }

      &.unselected {
        animation-duration: 3.25s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: $brand-color;
        background: linear-gradient(
          to right,
          transparent 10%,
          rgba(76, 132, 255, 0.2) 28%,
          transparent 33%
        );
        background-size: 800px 104px;

        position: relative;

        @keyframes placeHolderShimmer {
          0% {
            background-position: -468px 0;
          }
          100% {
            background-position: 468px 0;
          }
        }

        &:hover {
          background-color: $brand-color;
        }
      }
    }
  }
  
  &--purple {
    border: 2px solid $netlink-purple;
    background-color: $netlink-purple;
    color: $black;

    &:hover {
      border: 2px solid $netlink-purple;
    }

    &.btn--outline {
      color: $netlink-purple;
      background-color: transparent;

      &:hover {
        background-color: $netlink-purple;
        color: $white;
      }

      &.active {
        background-color: $netlink-purple;
        color: $white;
      }
    }
  }

  &--success {
    border: 2px solid $success;
    background-color: $success;
    color: $black;

    &:hover {
      border: 2px solid $success;
    }

    &.btn--outline {
      color: $success;
      background-color: transparent;

      &:hover {
        background-color: $success;
        color: $white;
      }

      &.active {
        background-color: $success;
        color: $white;
      }
    }
  }

  &--success {
    background-color: $success;
    color: $black;

    &:hover {
      background-color: $success-hover;
    }
  }

  &--danger {
    border: 2px solid $danger;
    background-color: $danger;
    color: $black;

    &:hover {
      border: 2px solid $danger-hover;
    }

    &.btn--outline {
      color: $danger;
      background-color: transparent;

      &:hover {
        background-color: $danger;
        color: $white;
      }

      &.active {
        background-color: $danger;
        color: $white;
      }
    }
  }
  
  &--white {
    width: 240px;
    border: 2px solid $white;
    background-color: $brand-color;
    color: $white;

    &:hover {
      border: 2px solid $white;
    }

    &.btn--outline {
      color: $white;
      background-color: transparent;

      &:hover {
        cursor: pointer;
        background-color: $white;
        color: $brand-color;
      }

      &.active {
        cursor: pointer;
        background-color: $white;
        color: $brand-color;
      }
    }
  }

  &--login {
    background-color: transparent;
    display: block;
    height: 60px;
    width: 60px ;
    max-width: 60px ;
    min-width: 60px ;
    border-radius: 50%;
    margin-left: 180px;
    padding: 0px;
    border: 2px solid $white;
    background-color: transparent;
    color: white;
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  &--danger {
    background-color: $danger;
    color: $black;

    &:hover {
      background-color: $danger-hover;
    }
  }

  &--round {
    height: 50px;
    //border-radius: 30px;
    border-radius: 5px;
    font-size: 15px;
  }

  &--withIcon {
    padding: 14px 15px;

    .icon {
      margin-right: 10px;
    }
  }

  &--small {
    padding: 0 5px;
    min-width: 120px;
    height: 40px;
  }

  &--middle {
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    border: 2px solid $brand-color;
    background-color: $brand-color;
    color: $white;

    &:hover {
      background-color: $brand-color-hover;
    }

    &.btn--outline {
      color: $brand-color;
      background-color: $white;

      &:hover {
        background-color: $brand-color;
        color: $white;
      }

      &.active {
        background-color: $brand-color;
        color: $white;
      }
    }

    &.cancel {
      background-color: $cool-grey;
      border: 2px solid $gunmetal;

      &:hover {
        background-color: $gunmetal;
      }
    }
  }
}
