@import '../variables';

html,
body {
    height: 100% !important
}

body {
    width: 100%;
    height: 100%;
    background: $body-bg;
    overflow-y: hidden;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.sup {
    color: #AD0600
}

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale
}

sup {
    color: $danger;
    margin-right: 5px;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #a29f99;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #a29f99;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #a29f99;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #a29f99;
}